import Vue, { PropType } from 'vue';
import IconBase from '../icon';
import { TdIconSVGProps, IconProps, SVGJson } from '../utils/types';
import useSizeProps from '../utils/use-size-props';

const element: SVGJson = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M13.04 4.46l-.92-.92L7.66 8l4.46 4.46.92-.92L9.5 8l3.54-3.54z","fillOpacity":0.9}},{"tag":"path","attrs":{"fill":"currentColor","d":"M8.04 4.46l-.92-.92L2.66 8l4.46 4.46.92-.92L4.5 8l3.54-3.54z","fillOpacity":0.9}}]};

const ChevronLeftDouble = Vue.extend<IconProps>({
  name: 'ChevronLeftDoubleIcon',
  functional: true,
  props: {
    // small/medium/large/xl/18px/2em
    size: {
      type: String,
    },
    onClick: {
      type: Function as PropType<TdIconSVGProps['onClick']>,
    },
  },
  render(createElement, context) {
    const { props, data } = context;
    const { size, ...otherProps } = props;

    const { className, style } = useSizeProps(size);
    const fullProps = {
      ...otherProps || {},
      id: 'chevron-left-double',
      icon: element,
      staticClass: className,
      style,
    };
    data.props = fullProps;
    return createElement(IconBase, data);
  },
});

export default ChevronLeftDouble;
