import Vue, { PropType } from 'vue';
import IconBase from '../icon';
import { TdIconSVGProps, IconProps, SVGJson } from '../utils/types';
import useSizeProps from '../utils/use-size-props';

const element: SVGJson = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M2 13V8h1v2.3l3-3 5.7 5.7H13V3H8V2h5a1 1 0 011 1v10a1 1 0 01-1 1H3a1 1 0 01-1-1zm4-4.3l-3 3V13h7.3L6 8.7z","fillOpacity":0.9}},{"tag":"path","attrs":{"fill":"currentColor","d":"M12 6a2 2 0 11-4 0 2 2 0 014 0zm-1 0a1 1 0 10-2 0 1 1 0 002 0zM6.28 5.56l-.7.7-1.42-1.4-1.41 1.4-.71-.7 1.41-1.41-1.41-1.42.7-.7 1.42 1.4 1.41-1.4.71.7-1.41 1.42 1.41 1.4z","fillOpacity":0.9}}]};

const ImageError = Vue.extend<IconProps>({
  name: 'ImageErrorIcon',
  functional: true,
  props: {
    // small/medium/large/xl/18px/2em
    size: {
      type: String,
    },
    onClick: {
      type: Function as PropType<TdIconSVGProps['onClick']>,
    },
  },
  render(createElement, context) {
    const { props, data } = context;
    const { size, ...otherProps } = props;

    const { className, style } = useSizeProps(size);
    const fullProps = {
      ...otherProps || {},
      id: 'image-error',
      icon: element,
      staticClass: className,
      style,
    };
    data.props = fullProps;
    return createElement(IconBase, data);
  },
});

export default ImageError;
