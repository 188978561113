import Vue, { PropType } from 'vue';
import IconBase from '../icon';
import { TdIconSVGProps, IconProps, SVGJson } from '../utils/types';
import useSizeProps from '../utils/use-size-props';

const element: SVGJson = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M3.74 6.68L7.5 2.9v8.59h1V2.91l3.76 3.77.71-.7-4.62-4.63a.5.5 0 00-.7 0L3.03 5.97l.7.7z","fillOpacity":0.9}},{"tag":"path","attrs":{"fill":"currentColor","d":"M2 11v2a1 1 0 001 1h10a1 1 0 001-1v-2h-1v2H3v-2H2z","fillOpacity":0.9}}]};

const Upload = Vue.extend<IconProps>({
  name: 'UploadIcon',
  functional: true,
  props: {
    // small/medium/large/xl/18px/2em
    size: {
      type: String,
    },
    onClick: {
      type: Function as PropType<TdIconSVGProps['onClick']>,
    },
  },
  render(createElement, context) {
    const { props, data } = context;
    const { size, ...otherProps } = props;

    const { className, style } = useSizeProps(size);
    const fullProps = {
      ...otherProps || {},
      id: 'upload',
      icon: element,
      staticClass: className,
      style,
    };
    data.props = fullProps;
    return createElement(IconBase, data);
  },
});

export default Upload;
